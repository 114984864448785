
import { Component, Vue } from 'vue-property-decorator'
import * as echarts from 'echarts'

@Component({
  name: 'OverView'
})
export default class HouseScreen extends Vue {
  private page = 1
  private size = 9
  // private total = 0
  private activeName = '7'

  private pageSizes = [2, 4, 9]

  private deviceData = {} as any

  private roomData = {} as any

  private waterData = {} as any

  private waterEcharsData = {} as any

  private wainingData = []

  private taskData = []

  private echart: any

  created () {
    this.getDevice()
    this.getRoom()
    this.getWater()
    this.getWaing()
    this.getTask()
  }

  mounted () {
    this.getEchars()
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }

  getDevice () {
    this.$axios.get(this.$apis.smartHouse.selectDeviceTotal).then(res => {
      this.deviceData = res
    })
  }

  getRoom () {
    this.$axios.get(this.$apis.smartHouse.selectRoomTotal).then(res => {
      this.roomData = res
    })
  }

  getWater () {
    this.$axios.get(this.$apis.smartHouse.selectWaterTotal).then(res => {
      console.log('res1111', res)
      this.waterData = res
    })
  }

  getWaing () {
    this.$axios.get(this.$apis.smartHouse.selectSqWarningByPage, {
      page: this.page,
      size: this.size
    }).then(res => {
      this.wainingData = res.list
      // this.total = res.total
    })
  }

  // 获取表格
  getTask () {
    this.$axios.get(this.$apis.smartHouse.selectSqIrrigationPlanByPage, {
      page: 1,
      size: 4,
      planStatus: 'Y'
    }).then(res => {
      // res.list.splice(3, 6)
      this.taskData = res.list
    })
  }

  getEchars () {
    this.$axios.get(this.$apis.smartHouse.selectWaterLine2, {
      dayNum: this.activeName
    }).then((res) => {
      // 基于准备好的dom，初始化echarts实例
      this.echart = echarts.init(document.getElementById('echars_content') as any)
      const option = {
        xAxis: {
          type: 'category',
          data: res.date,
          axisTick: { // 坐标轴刻度标签
            show: false
          },
          axisLine: { // 坐标轴轴线
            show: false
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: res.dayLacc,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 4,
              shadowBlur: 0,
              shadowColor: 'rgba(44, 142, 255, 0.2)',
              shadowOffsetY: 20
            },
            itemStyle: {
              normal: {
                borderWidth: 4,
                borderColor: 'rgba(44, 142, 255, 1)'
              }
            }
          }
        ],
        tooltip: {
          show: true,
          formatter: (params: any) => {
            return `${params.name}<br />水量： ${Number(params.value)?.toFixed(3)}${params.value !== '0' ? 'm³' : ''}`
          }
        },
        grid: {
          left: 50,
          top: 20,
          bottom: 30
        }
      }
      // 使用刚指定的配置项和数据显示图表。
      this.echart.setOption(option)
    })
  }

  goTask () {
    this.$router.push({
      path: '/smartHouse/task'
    })
  }

  goWarning () {
    this.$router.push({
      path: '/smartHouse/alarm'
    })
  }

  goBuild () {
    this.$router.push({
      path: '/smartHouse/build'
    })
  }

  handleClick () {
    console.log('123')
    this.getEchars()
  }
}
